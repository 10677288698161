<template>
  <div>
    <loading
        :active.sync="isLoading"
        background-color="rgba(0, 0, 0, 0.6)"
        loader="dots"
        color="rgb(36, 170, 230)"
        :is-full-page="true"
    />
    <dealer-header v-if="isCurrentUserDealer"/>
    <structural-subdivision-user-header v-if="isCurrentUserStructuralSubdivisionUser"/>
    <div class="full-section">
      <router-view/>
    </div>
    <dealer-footer v-if="isCurrentUserDealer"/>
    <structural-subdivision-user-footer v-if="isCurrentUserStructuralSubdivisionUser"/>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {mapGetters} from "vuex";
import {mapActions} from "vuex";
import DealerHeader from "../components/header/DealerHeader";
import DealerFooter from "../components/footer/DealerFooter";
import StructuralSubdivisionUserHeader from "../components/header/StructuralSubdivisionUserHeader";
import StructuralSubdivisionUserFooter from "../components/footer/StructuralSubdivisionUserFooter";

export default {
  name: "PublicAreaContainer",
  components: {Loading, StructuralSubdivisionUserFooter, StructuralSubdivisionUserHeader, DealerFooter, DealerHeader},
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('account', ['accountInfo']),
    ...mapActions('catalog', ['fetchSectionList']),
  }
}
</script>

<style scoped>

</style>
