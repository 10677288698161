<template>
    <router-link :class="css_class" v-if="getPages.find(x => x.type == this.type)" :to="slug">{{ getPages.find(x => x.type == this.type).title }}</router-link>
    <router-link :class="css_class" v-else :to="slug">{{title}}</router-link>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "StaticPage",
    props: {
        css_class: null,
        type: null,
        slug: null,
        title: String
    },
    data: () => ({
        page: null,
    }),
    computed: {
        ...mapState({
            getPages: state => state.staticPages.staticList,
        }),
    },
}
</script>

<style scoped>

</style>
