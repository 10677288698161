<template>
    <footer class="py-5">
        <div class="container">
            <div class="row">
                <div class="col-sm-3 col-12 logo-section">
                    <a href="#"> <img src="@/assets/images/logo.png" class="logo" alt="Лукойл"></a>
                    <div class="text">
                        Дилерский портал для заказа материалов, полиграфии и услуг
                    </div>
                </div>
                <div class="col-lg-3 col-xl-3 col-md-3 col-sm-3 col-4 footer-nav">
                    <ul>
                        <li><router-link to="/contacts">Контакты</router-link></li>
                        <li><router-link to="/profile">Личный кабинет</router-link></li>
                        <li><router-link to="/faq">FAQ</router-link></li>
                    </ul>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-5 footer-nav">
                    <ul>
                        <li><static-page type="1" slug="/delivery"/></li>
                        <li><static-page type="2" slug="/how-to-order"/></li>
                        <li><static-page type="3" slug="/about"/></li>
                        <li><static-page type="4" slug="/visits"/></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import StaticPage from "../../components/static-pages/StaticPage";

export default {
    name: "DealerFooter",
    components: {StaticPage}
}
</script>

<style scoped>
footer {
    background-color: #000000;
}

.logo {
    width: 139px;

}
.text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ffffff;

}
.footer-nav {
    float: left
}
.footer-nav ul li {
    list-style: none;
    line-height: 25px;
    margin-left: 10px;
}
.footer-nav ul li a {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

}
@media (max-width: 980px) {
    .footer-nav ul {
        padding-left: 15px;
    }
}
@media (max-width: 570px) {
    .logo-section {
        margin-bottom: 30px;
        display: flex;
    }
    .footer-nav ul {
        padding-left: 0;
    }
    .footer-nav ul li {
        margin-left: 0;
    }
    .logo-section .text {
        margin-left: 30px;
        margin-top: 0;
    }
    .footer-nav ul li a {
        font-size: 9px;
    }
    footer .logo {
        width: 120px;
    }

}
</style>
