<template>
    <footer class="py-5">
        <div class="container">
            <div class="row">
                <div class="col-sm-3 col-12 logo-section">
                    <router-link :to="{name: 'WorkRules'}">
                      <img src="@/assets/images/logo.png" class="logo" alt="Лукойл">
                    </router-link>
                    <div class="text">
                        Заказ сувенирной продукции
                    </div>
                </div>

                <div class="col-lg-3 col-xl-3 col-md-3 col-sm-3 col-4 footer-nav">
                    <ul>
                        <li><router-link :to="{name: 'Contacts'}">Контакты</router-link></li>
                        <li><router-link :to="{name: 'Profile'}">Личный кабинет</router-link></li>
                    </ul>
                </div>

                <div class="col-lg-3 col-xl-3 col-md-3 col-sm-3 col-4 footer-nav">
                    <ul>
                        <li><a href="/catalog" @click.prevent="goToSection(sectionsList ? sectionsList[0] : null)">Каталог товаров</a></li>
                        <li><static-page type="2" slug="/how-to-order"/></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import StaticPage from "../static-pages/StaticPage";
import {mapActions, mapGetters} from "vuex";

export default {
    components: {
      StaticPage
    },
    name: "StructuralSubdivisionUserFooter",
    methods: {
      ...mapActions('catalog', ['goToSection'])
    },
    computed: {
      ...mapGetters('catalog', ['sectionsList'])
    }
}
</script>

<style scoped>
footer {
    background-color: #000000;
}

.logo {
    width: 139px;

}

.text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ffffff;

}

.footer-nav {
    float: left
}

.footer-nav ul li {
    list-style: none;
    line-height: 25px;
    margin-left: 10px;
}

.footer-nav ul li a {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

}

@media (max-width: 980px) {
    .footer-nav ul {
        padding-left: 15px;

    }

}

@media (max-width: 570px) {
    .logo-section {
        margin-bottom: 30px;
        display: flex;
    }

    .footer-nav ul {
        padding-left: 0;
    }

    .footer-nav ul li {
        margin-left: 0;
    }

    .logo-section .text {
        margin-left: 30px;
        margin-top: 0;
    }

    .footer-nav ul li a {
        font-size: 9px;
    }

    footer .logo {
        width: 120px;
    }

}
</style>
